import type {CohortFormMediaTriggerStruct} from '@cohort/shared/apps/cohort-form/triggers/media';
import type {WalletAssetKind} from '@cohort/shared/schema/common/assets';
import {AllowedAssetMimeTypes} from '@cohort/shared/schema/common/assets';
import {isCohortError} from '@cohort/shared/schema/common/errors';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {getFileType} from '@cohort/shared-frontend/utils/fileUploads';
import {
  isEmptyFileList,
  isFile,
  isFileList,
  isLandscapeImage,
} from '@cohort/shared-frontend/utils/isFile';
import type {TriggerIntegrationUsageComponentProps} from '@cohort/wallet/apps/TriggerIntegration';
import Button from '@cohort/wallet/components/button/Button';
import ProgressButton from '@cohort/wallet/components/button/ProgressButton';
import {EmbeddedTriggerCta} from '@cohort/wallet/components/challenges/NextStepCta';
import {CheckboxInput} from '@cohort/wallet/components/forms/CheckboxInput';
import {
  FileInput,
  FileInputPreviewImage,
  FileInputUpload,
  FileInputUploader,
  FileInputVideoPreview,
} from '@cohort/wallet/components/forms/fileInput/FileInput';
import {getAcceptedAssetKindsForMediaInput} from '@cohort/wallet/components/forms/fileInput/utils';
import {DrawerModal} from '@cohort/wallet/components/modals/Modal';
import type {FeatureFlags} from '@cohort/wallet/FeatureFlags';
import {useCohortMutation} from '@cohort/wallet/hooks/api/Query';
import useNotify from '@cohort/wallet/hooks/notify';
import useChallengeContext from '@cohort/wallet/hooks/useChallengeContext';
import {useScreenSize} from '@cohort/wallet/hooks/useScreenSize';
import useThemeContext from '@cohort/wallet/hooks/useThemeContext';
import {uploadAsset} from '@cohort/wallet/lib/Utils';
import {zodResolver} from '@hookform/resolvers/zod';
import {useIsMutating} from '@tanstack/react-query';
import {useFlags} from 'launchdarkly-react-client-sdk';
import {motion} from 'motion/react';
import {Fragment, useEffect, useMemo, useState} from 'react';
import type {
  Control,
  FieldError,
  FieldErrors,
  FieldErrorsImpl,
  FieldValues,
  Merge,
  UseFormRegister,
} from 'react-hook-form';
import {useController, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {isDefined} from 'remeda';
import {match, P} from 'ts-pattern';
import {z} from 'zod';

const ErrorMessage: React.FC<{
  error: string | FieldError | Merge<FieldError, FieldErrorsImpl<FieldValues>> | undefined;
}> = ({error}) => {
  if (!error) return null;

  return (
    <motion.p className="mt-2 text-red-500" initial={{opacity: 0}} animate={{opacity: 1}}>
      {error.toString()}
    </motion.p>
  );
};

type MediaUploadButtonProps = {
  control: Control<CohortFormMedia>;
  register: UseFormRegister<CohortFormMedia>;
  assetKind: WalletAssetKind;
  errors: FieldErrors<CohortFormMedia>;
  configBtnText: () => string;
};

const MediaUploadButton: React.FC<MediaUploadButtonProps> = ({
  control,
  register,
  assetKind,
  errors,
  configBtnText,
}) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const error = errors.answer?.message;

  return (
    <FileInput name="answer" control={control} register={register} assetKind={assetKind}>
      <div className="relative">
        <Button
          variant="primary"
          className="w-full"
          tracking={{
            namespace: `triggerIntegrations.media-form.submit`,
          }}
          loading={isProcessing}
        >
          <FileInputUpload
            name="answer"
            assetKind={assetKind}
            accept={AllowedAssetMimeTypes[assetKind].options.join(',')}
            setIsProcessing={setIsProcessing}
          />
          {configBtnText()}
        </Button>
        <ErrorMessage error={error} />
      </div>
    </FileInput>
  );
};

const FileInputPreviewWrapper: React.FC<{
  control: Control<CohortFormMedia>;
  children: React.ReactNode;
}> = ({control, children}) => {
  const {isMobile} = useScreenSize();
  const {field} = useController({control, name: 'answer'});
  const handleClose = (): void => field.onChange(null);

  return isMobile ? (
    <DrawerModal
      tracking={{
        namespace: 'modals.triggerIntegrations.tiktok.keywordInDescription.communitySubmission',
        metadata: {},
      }}
      height="fit-content"
      onClose={handleClose}
    >
      {children}
    </DrawerModal>
  ) : (
    <Fragment>{children}</Fragment>
  );
};

type FileInputWithPreviewProps = {
  control: Control<CohortFormMedia>;
  register: UseFormRegister<CohortFormMedia>;
  uploadProgress: number;
  loading: boolean;
  disabled: boolean;
  assetKind: WalletAssetKind;
  hasMedia: boolean;
  isAnswerDefined: boolean;
  error: string | FieldError | Merge<FieldError, FieldErrorsImpl<FieldValues>> | undefined;
};

const FileInputWithPreview: React.FC<FileInputWithPreviewProps> = ({
  control,
  register,
  uploadProgress,
  loading,
  disabled,
  assetKind,
  hasMedia,
  isAnswerDefined,
  error,
}) => {
  const [hasImageLandscapeRatio, setHasImageLandscapeRatio] = useState(false);
  const {t} = useTranslation('app-cohort-form', {
    keyPrefix: 'triggerIntegrations.media',
  });
  const {campaign} = useChallengeContext();
  const {isMobile} = useScreenSize();
  const {hasDarkBg} = useThemeContext();

  const {field} = useController({control, name: 'answer'});
  const answer = field.value;
  const handleClose = (): void => field.onChange(null);

  useEffect(() => {
    if (isFile(answer) && !isEmptyFileList(answer) && getFileType(answer) === 'image') {
      isLandscapeImage(answer).then(setHasImageLandscapeRatio);
    }
  }, [answer]);

  const FileInputPreviewMemo = useMemo(() => {
    if (!isFile(answer) || isEmptyFileList(answer)) {
      return null;
    }

    const fileType = getFileType(answer);
    return match(fileType)
      .with('image', () => (
        <FileInputPreviewImage
          width="full"
          className={!isMobile && !hasImageLandscapeRatio ? 'mx-auto w-2/3' : undefined}
        />
      ))
      .with('video', () => (
        <FileInputVideoPreview fileInputValue={answer} hideCloseBtn width="full" />
      ))
      .otherwise(() => {
        throw new Error('File type not supported');
      });
  }, [answer, hasImageLandscapeRatio, isMobile]);

  const SocialLayerSectionMemo = useMemo(
    () => <SocialLayerConsent register={register} hasDarkBg={hasDarkBg} />,
    [register, hasDarkBg]
  );

  if (hasMedia && !isAnswerDefined) {
    return null;
  }

  let fileInputHeight = 460;
  if (isMobile) {
    fileInputHeight = campaign.socialLayerEnabled ? 250 : 500;
  }

  if (isFile(answer) && !isEmptyFileList(answer)) {
    const fileType = getFileType(answer);
    const ProgressButtonWrapper = isMobile ? Fragment : EmbeddedTriggerCta;

    return (
      <FileInput name="answer" control={control} register={register} assetKind={assetKind}>
        <FileInputPreviewWrapper control={control}>
          <div className="flex w-full flex-col items-center gap-8">
            {FileInputPreviewMemo}
            {fileType === 'image' && SocialLayerSectionMemo}
            <ProgressButtonWrapper>
              <div className="flex w-full flex-col gap-4">
                <ProgressButton
                  form="media-form"
                  tracking={{
                    namespace: `triggerIntegrations.media-form.submit`,
                  }}
                  variant="primary"
                  className="w-full"
                  type="submit"
                  progress={uploadProgress}
                  loading={loading}
                  disabled={disabled}
                >
                  {t('submit')}
                </ProgressButton>
                <Button
                  className="w-full"
                  variant="secondary"
                  onClick={handleClose}
                  tracking={{
                    namespace:
                      'modals.triggerIntegrations.cohortForm.media.communitySubmission.close',
                    metadata: undefined,
                  }}
                  disabled={loading}
                >
                  {t('cancel')}
                </Button>
              </div>
            </ProgressButtonWrapper>
          </div>
        </FileInputPreviewWrapper>
      </FileInput>
    );
  }

  return (
    <>
      <FileInput name="answer" control={control} register={register} assetKind={assetKind}>
        <FileInputUploader {...(hasMedia ? {} : {height: fileInputHeight})} />
      </FileInput>
      <ErrorMessage error={error} />
    </>
  );
};

type SocialLayerConsentProps = {
  register: UseFormRegister<CohortFormMedia>;
  hasDarkBg: boolean;
};

const SocialLayerConsent: React.FC<SocialLayerConsentProps> = ({register, hasDarkBg}) => {
  const {campaign} = useChallengeContext();
  const {t} = useTranslation('app-cohort-form', {
    keyPrefix: 'triggerIntegrations.media',
  });

  if (!campaign.socialLayerEnabled) {
    return null;
  }

  return (
    <label className="flex cursor-pointer gap-3">
      <CheckboxInput register={register('socialLayerConsent')} />
      <span className={cn('text-sm font-normal', hasDarkBg ? 'text-white' : 'text-black/90')}>
        {t('socialLayerConsent')}
      </span>
    </label>
  );
};
const fileValidatorRequired = (file: string | File | FileList): boolean => {
  if (isFileList(file) && file.length === 0) {
    return false;
  }
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return file !== null && file !== undefined;
};

const CohortFormMediaSchema = z.object({
  answer: z.any().refine(fileValidatorRequired),
  socialLayerConsent: z.boolean(),
});
type CohortFormMedia = z.infer<typeof CohortFormMediaSchema>;

const CohortFormMediaUsageComponent: React.FC<
  TriggerIntegrationUsageComponentProps<CohortFormMediaTriggerStruct>
> = ({onTriggerIntegrationUsageSuccess, config, stepId, hasMedia, handleAnswerChange}) => {
  const {t} = useTranslation('app-cohort-form', {
    keyPrefix: 'triggerIntegrations.media',
  });

  const {featureVideoModeration} = useFlags<FeatureFlags>();
  const notify = useNotify();
  const {
    control,
    handleSubmit,
    register,
    setValue,
    watch,
    formState: {errors, isValid},
  } = useForm<CohortFormMedia>({
    resolver: zodResolver(CohortFormMediaSchema),
    defaultValues: {
      socialLayerConsent: false,
      answer: null,
    },
  });
  const [uploadProgress, setUploadProgress] = useState(0);
  const ongoingVerifications = useIsMutating({
    mutationKey: ['verifyStep', stepId],
  });
  const isVerifying = ongoingVerifications > 0;

  const onUploadError = (error: Error | unknown): void => {
    if (isCohortError(error, 'media.forbidden-content')) {
      setValue('answer', null);
      notify('error', t('errorFileUploadForbiddenContent'));
      return;
    }
    notify('error', t('errorFileUploadFailed'));
  };

  const {isLoading: isUploading, mutateAsync: uploadFile} = useCohortMutation({
    mutationFn: async ({file, assetKind}: {file: File; assetKind: WalletAssetKind}) =>
      uploadAsset(file, assetKind, featureVideoModeration, progress => setUploadProgress(progress)),
    onError: (error: Error) => onUploadError(error),
  });

  const error = errors.answer?.message;
  const answer = watch('answer');
  const assetKind = getAcceptedAssetKindsForMediaInput(config.mediaType);
  const configBtnText = (): string => {
    return match({mediaType: config.mediaType, answer})
      .with(
        {
          mediaType: 'image',
          answer: P.when(answer => isEmptyFileList(answer) || !isDefined(answer)),
        },
        () => t('submitBtnImage')
      )
      .with(
        {
          mediaType: 'video',
          answer: P.when(answer => isEmptyFileList(answer) || !isDefined(answer)),
        },
        () => t('submitBtnVideo')
      )
      .with(
        {
          mediaType: 'imageOrVideo',
          answer: P.when(answer => isEmptyFileList(answer) || !isDefined(answer)),
        },
        () => t('submitBtnImageOrVideo')
      )
      .with(
        {
          answer: P.when(answer => isDefined(answer)),
        },
        () => t('submit')
      )
      .exhaustive();
  };

  useEffect(() => {
    handleAnswerChange(answer);
  }, [answer, handleAnswerChange]);

  const isAnswerDefined = isDefined(answer) && !isEmptyFileList(answer);

  return (
    <form
      id="media-form"
      className="md:min-w-[350px]"
      onSubmit={handleSubmit(async data => {
        if (data.answer && isFile(data.answer)) {
          // if the file gets moderated, the upload will fail
          const asset = await uploadFile({
            file: data.answer,
            assetKind: assetKind,
          }).catch(() => null);

          if (asset) {
            onTriggerIntegrationUsageSuccess({
              answer: asset.fileKey,
              socialLayerConsent: data.socialLayerConsent,
            });
          }
        }
      })}
    >
      <FileInputWithPreview
        control={control}
        register={register}
        uploadProgress={uploadProgress}
        loading={isUploading || isVerifying}
        disabled={!isValid}
        assetKind={assetKind}
        hasMedia={hasMedia}
        isAnswerDefined={isAnswerDefined}
        error={error}
      />

      {/* @Devs - When the step has a custom media, we hide the fileInput above and the CTA becomes the FileInput. */}
      {(!isDefined(answer) || isEmptyFileList(answer)) && (
        <EmbeddedTriggerCta removeCloseBar>
          <MediaUploadButton
            control={control}
            register={register}
            assetKind={assetKind}
            errors={errors}
            configBtnText={configBtnText}
          />
        </EmbeddedTriggerCta>
      )}
    </form>
  );
};

export default CohortFormMediaUsageComponent;
