import type {ConnectorId} from '@cohort/shared/apps';
import ConnectButton from '@cohort/wallet/apps/ConnectButton';
import {getAppFromTriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';
import Button from '@cohort/wallet/components/button/Button';
import ActionCtaComponentWrapper from '@cohort/wallet/components/challenges/ActionCtaComponentWrapper';
import {DrawerModal} from '@cohort/wallet/components/modals/Modal';
import useChallengeContext from '@cohort/wallet/hooks/useChallengeContext';
import {useScreenSize} from '@cohort/wallet/hooks/useScreenSize';
import {useVerifyStep} from '@cohort/wallet/hooks/useStepVerification';
import type {
  ChallengeStepTriggerWDto,
  ChallengeStepWDto,
} from '@cohort/wallet-schemas/challengeSteps';
import {Fragment, useEffect, useState} from 'react';
import {createPortal} from 'react-dom';
import {useTranslation} from 'react-i18next';

export type TriggerCtaTrackingMetadata = {
  campaignId: string;
  appId: string;
  stepId: string;
  triggerId: string;
  challengeParticipationId?: string;
};

type TriggerCtaProps = {
  challengeStepId: string;
  trigger: ChallengeStepTriggerWDto;
};

type VerifyButtonProps = {
  challengeStepId: string;
  participationId: string;
  trigger: ChallengeStepTriggerWDto;
  trackingMetadata: TriggerCtaTrackingMetadata;
};

type NextStepCtaProps = {
  step: ChallengeStepWDto;
};

const VerifyButton: React.FC<VerifyButtonProps> = ({
  challengeStepId,
  participationId,
  trigger,
  trackingMetadata,
}) => {
  const {t} = useTranslation('components', {keyPrefix: 'challenges.triggerCta'});
  const userConnection = trigger.connection;
  const {processStepVerification, isVerificationLoading} = useVerifyStep();
  const currentApp = getAppFromTriggerIntegration(trigger.triggerIntegrationId);
  const triggerIntegration = currentApp.triggerIntegrations.find(
    integration => integration.spec.id === trigger.triggerIntegrationId
  );

  return (
    <Button
      variant="primary"
      className="w-full"
      type="button"
      tracking={{
        namespace: 'challenges.play.verify',
        metadata: trackingMetadata,
      }}
      loading={isVerificationLoading}
      onClick={() =>
        processStepVerification(
          {
            challengeStepId,
            challengeStepTriggerId: trigger.id,
            userConnectionId: userConnection?.id ?? null,
            participationId,
          },
          triggerIntegration?.disableExponentialBackoff
        )
      }
    >
      {t('verify')}
    </Button>
  );
};

const TriggerCta: React.FC<TriggerCtaProps> = ({challengeStepId, trigger}) => {
  const {campaign} = useChallengeContext();
  const {t} = useTranslation('components', {keyPrefix: 'challenges.triggerCta'});
  const currentApp = getAppFromTriggerIntegration(trigger.triggerIntegrationId);
  const triggerIntegration = currentApp.triggerIntegrations.find(
    integration => integration.spec.id === trigger.triggerIntegrationId
  );
  const userConnection = trigger.connection;

  const trackingMetadata = {
    campaignId: campaign.id,
    appId: currentApp.spec.id,
    stepId: challengeStepId,
    triggerId: trigger.id,
    challengeParticipationId: campaign.challenge.participation?.id,
  } satisfies TriggerCtaTrackingMetadata;

  if (
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    currentApp.spec.userConnector !== null &&
    (!userConnection || userConnection.status === 'broken')
  ) {
    const isConnectionBroken = userConnection?.status === 'broken';

    return (
      <ConnectButton
        app={currentApp}
        className="w-full"
        type="button"
        text={
          isConnectionBroken
            ? t('reconnectApp', {appName: currentApp.spec.name})
            : t('connectApp', {appName: currentApp.spec.name})
        }
        tracking={{
          namespace: 'challenges.play.connect',
          metadata: trackingMetadata,
        }}
        connectorId={currentApp.spec.userConnector.id as ConnectorId}
        existingConnectionId={userConnection?.id}
        userDataPolicyPosition="right"
        context={{trigger, challenge: campaign.challenge}}
      />
    );
  }

  if (triggerIntegration?.type === 'verification') {
    return (
      <VerifyButton
        trigger={trigger}
        challengeStepId={challengeStepId}
        participationId={campaign.challenge.participation?.id ?? ''}
        trackingMetadata={trackingMetadata}
      />
    );
  }

  return (
    <ActionCtaComponentWrapper
      trigger={trigger}
      challengeStepId={challengeStepId}
      participationId={campaign.challenge.participation?.id ?? ''}
      trackingMetadata={trackingMetadata}
    />
  );
};

const EmbeddedTriggerCta: React.FC<{children: React.ReactNode; removeCloseBar?: boolean}> = ({
  children,
  removeCloseBar = false,
}) => {
  const [ctaElement, setCtaElement] = useState<HTMLElement | null>(null);

  useEffect(() => {
    const ctaElement = document.getElementById('challenge-play-next-step-cta');

    if (ctaElement) {
      setCtaElement(ctaElement);
    }
  }, []);

  if (!ctaElement) {
    return null;
  }

  return createPortal(
    <NextStepCtaWrapper removeCloseBar={removeCloseBar}>{children}</NextStepCtaWrapper>,
    ctaElement
  );
};

const NextStepCtaWrapper: React.FC<{children: React.ReactNode; removeCloseBar?: boolean}> = ({
  children,
  removeCloseBar = false,
}) => {
  const {campaign} = useChallengeContext();
  const {isMobile} = useScreenSize();

  return isMobile ? (
    <DrawerModal
      allowBackgroundScroll
      tracking={{
        namespace: 'challenges.play.next',
        metadata: {campaignId: campaign.id},
      }}
      removeCloseBar={removeCloseBar}
    >
      {children}
    </DrawerModal>
  ) : (
    <Fragment>{children}</Fragment>
  );
};

const NextStepCta: React.FC<NextStepCtaProps> = ({step}) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const trigger = step.triggers[0]!;
  const currentApp = getAppFromTriggerIntegration(trigger.triggerIntegrationId);
  const triggerIntegration = currentApp.triggerIntegrations.find(
    integration => integration.spec.id === trigger.triggerIntegrationId
  );

  return (
    <div id="challenge-play-next-step-cta">
      {triggerIntegration?.type !== 'embedded' && (
        <NextStepCtaWrapper>
          <TriggerCta challengeStepId={step.id} trigger={trigger} />
        </NextStepCtaWrapper>
      )}
    </div>
  );
};

export {NextStepCta, NextStepCtaWrapper, EmbeddedTriggerCta};
