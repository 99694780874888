import {defaultErrorMessage} from '@cohort/shared/models';
import type {AssetKind, WalletAssetKind} from '@cohort/shared/schema/common/assets';
import {AllowedAssetMimeTypes} from '@cohort/shared/schema/common/assets';
import type {AssetMinDimension, SizedAssetKind} from '@cohort/shared/utils/fileUploads';
import {ASSETS_MIN_DIMENSIONS} from '@cohort/shared/utils/fileUploads';
import {getImageUrl, getVideoUrl, Sizes} from '@cohort/shared/utils/media';
import {isWalletImageFile} from '@cohort/shared/utils/mimeTypes';
import Spinner from '@cohort/shared-frontend/components/Spinner';
import VideoPlayer from '@cohort/shared-frontend/components/VideoPlayer';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {getFileType} from '@cohort/shared-frontend/utils/fileUploads';
import {isEmptyFileList, isFile} from '@cohort/shared-frontend/utils/isFile';
import Button from '@cohort/wallet/components/button/Button';
import type {FieldWrapperProps} from '@cohort/wallet/components/forms/FieldWrapper';
import FieldWrapper from '@cohort/wallet/components/forms/FieldWrapper';
import useFileInputContext from '@cohort/wallet/components/forms/fileInput/useFileInputContext';
import type {FormField} from '@cohort/wallet/components/forms/fileInput/utils';
import {compressImage, isValidFile} from '@cohort/wallet/components/forms/fileInput/utils';
import notify from '@cohort/wallet/components/toasts/Toast';
import {CameraPlus, X} from '@phosphor-icons/react';
import {CheckCircle, Image, VideoCamera} from '@phosphor-icons/react';
import React, {createContext, useEffect, useState} from 'react';
import type {FieldValues} from 'react-hook-form';
import {useController, useWatch} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {isDefined} from 'remeda';
import {match} from 'ts-pattern';

type BaseFileInputContextData = {
  assetKind: WalletAssetKind;
  disabled?: boolean;
};

export type FileInputContextType<T extends FieldValues = FieldValues> = FormField<T> &
  BaseFileInputContextData & {
    minFileDimensions?: AssetMinDimension;
  };

export const FileInputContext = createContext<FileInputContextType | undefined>(undefined);

type FileInputProps<T extends FieldValues> = FieldWrapperProps &
  FormField<T> &
  BaseFileInputContextData & {
    children: React.ReactNode;
    fieldWrapper?: Omit<FieldWrapperProps, 'children' | 'name'>;
  };

const FileInput = <T extends FieldValues>(props: FileInputProps<T>): JSX.Element => {
  const {assetKind, control, children, disabled, name, register, fieldWrapper} = props;

  const minFileDimensions = Object.keys(ASSETS_MIN_DIMENSIONS).includes(assetKind)
    ? ASSETS_MIN_DIMENSIONS[assetKind as SizedAssetKind]
    : undefined;
  const {fieldState} = useController({
    control,
    name,
  });

  const contextValue: FileInputContextType<T> = {
    assetKind,
    control,
    disabled,
    minFileDimensions,
    name,
    register,
  };

  const fieldWrapperProps: FieldWrapperProps | undefined = fieldWrapper
    ? {
        children,
        className: fieldWrapper.className,
        error: fieldState.error?.message,
        label: fieldWrapper.label,
        labelPosition: fieldWrapper.labelPosition,
      }
    : undefined;

  return (
    <FileInputContext.Provider value={contextValue as FileInputContextType<FieldValues>}>
      {fieldWrapperProps ? <FieldWrapper {...fieldWrapperProps} /> : children}
    </FileInputContext.Provider>
  );
};

/**
 * Handles the file input and preview logic, displaying a button if no file is selected.
 */
const FileInputUploader: React.FC<{height?: number}> = ({height}) => {
  const {control, name} = useFileInputContext();
  const fieldValue = useWatch({
    control,
    name,
  });

  if (fieldValue === '' || !isDefined(fieldValue) || isEmptyFileList(fieldValue)) {
    return <FileInputUploadButton height={height} />;
  }

  const fileType = getFileType(fieldValue);

  return match(fileType)
    .with('image', () => (
      <FileInputPreview>
        <FileInputPreviewImage>
          <FileInputDeleteButton />
        </FileInputPreviewImage>
        <FileInputPreviewFileMetadata />
        <FileInputPreviewUploadedFileMessage />
      </FileInputPreview>
    ))
    .with('video', () => (
      <FileInputPreview>
        <FileInputVideoPreview fileInputValue={fieldValue} />
        <FileInputPreviewFileMetadata />
      </FileInputPreview>
    ))
    .with('document', () => null)
    .with(undefined, () => null)
    .exhaustive();
};

type FileInputUploadProps = {
  assetKind: AssetKind;
  name: string;
  accept: string;
  setIsProcessing: (isProcessing: boolean) => void;
  className?: string;
};

/**
 * Input element for uploading files.
 */
const FileInputUpload: React.FC<FileInputUploadProps> = ({
  name,
  assetKind,
  accept,
  setIsProcessing,
}) => {
  const {control, disabled, minFileDimensions, register, rules} = useFileInputContext();
  const {t} = useTranslation('components', {keyPrefix: 'forms.fileInput'});
  const {field} = useController({control, name});

  // i18nOwl-ignore [errorFileTooLarge, errorInvalidFileType, errorImageTooSmall]
  return (
    <input
      key={name}
      className={cn(
        'absolute left-0 top-0 h-full w-full opacity-0 ring-opacity-100',
        disabled ? 'cursor-not-allowed' : 'cursor-pointer'
      )}
      data-testid={name}
      {...register(name, rules)}
      accept={accept}
      type="file"
      onChange={async e => {
        e.stopPropagation();
        if (e.target.files && e.target.files.length > 0) {
          const file = e.target.files[0] as File;

          if (await isValidFile(assetKind, file, minFileDimensions, t)) {
            if (isWalletImageFile(file.type)) {
              setIsProcessing(true);
              try {
                // Resizing to Full HD and converting to WebP with 90% quality
                // https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/toBlob#quality
                const compressedImage = await compressImage(file, {quality: 0.9, maxSize: 1920});
                field.onChange(compressedImage);
              } finally {
                setIsProcessing(false);
              }
            } else {
              field.onChange(file);
            }
          }
        }
      }}
      disabled={disabled}
      value={undefined}
    />
  );
};

const UPLOAD_ICON = {
  cohortFormImage: <Image size={24} className="text-[--xps-p-color]" />,
  cohortFormVideo: <VideoCamera size={24} className="text-[--xps-p-color]" />,
  cohortFormImageOrVideo: <CameraPlus size={24} className="text-[--xps-p-color]" />,
};

/**
 * Displays the file upload button and handles new file selections.
 */
const FileInputUploadButton: React.FC<{height?: number}> = ({height}) => {
  const {assetKind, disabled, name} = useFileInputContext();
  const [isProcessing, setIsProcessing] = useState(false);
  const {t} = useTranslation('components', {keyPrefix: 'forms.fileInput'});
  const acceptedFileTypes = AllowedAssetMimeTypes[assetKind as AssetKind].options;
  const buttonIcon = UPLOAD_ICON[assetKind];

  const getUploadLabel = (): string => {
    if (assetKind === 'cohortFormImage') {
      return t('labelUploadImage');
    }
    if (assetKind === 'cohortFormVideo') {
      return t('labelUploadVideo');
    }
    return t('labelUploadImageOrVideo');
  };

  return (
    <div
      id="fileUploadInput"
      className={cn(
        'border-slate relative flex min-h-[120px] justify-center rounded-md border-2 border-dashed bg-[--xps-input-background-color] text-center text-slate-400',
        disabled && 'bg-slate-100'
      )}
      style={{height}}
    >
      <div className="flex w-full flex-col items-center justify-center">
        <div
          className={cn(
            'relative flex h-full w-full flex-col items-center justify-center space-y-1 rounded-md p-5 ring-primary',
            isProcessing && 'pointer-events-none flex-1'
          )}
        >
          <FileInputUpload
            key={name}
            assetKind={assetKind}
            name={name}
            accept={acceptedFileTypes.join(',')}
            setIsProcessing={setIsProcessing}
          />
          {isProcessing ? (
            <div className="flex h-full items-center justify-center">
              <Spinner size={24} />
            </div>
          ) : (
            <>
              {buttonIcon}
              <p>{getUploadLabel()}</p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

type FileInputVideoPreviewProps = {
  fileInputValue: string | File;
  hideCloseBtn?: boolean;
  width?: number | string;
};

/**
 * Displays the video preview with play/pause functionality.
 */
const FileInputVideoPreview: React.FC<FileInputVideoPreviewProps> = ({
  fileInputValue,
  hideCloseBtn,
  width = 280,
}) => {
  const [thumbnailUrl, setThumbnailUrl] = useState<string | null>(null);

  useEffect(() => {
    const generateThumbnail = async (): Promise<void> => {
      if (isFile(fileInputValue)) {
        try {
          const video = document.createElement('video');
          video.preload = 'metadata';
          video.playsInline = true;
          video.muted = true;
          video.src = URL.createObjectURL(fileInputValue);

          await new Promise(resolve => {
            video.onloadedmetadata = () => {
              // Set a specific time (e.g., 1 second) to avoid black frame
              video.currentTime = 1;

              video.onseeked = () => {
                const canvas = document.createElement('canvas');
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;
                const ctx = canvas.getContext('2d');
                ctx?.drawImage(video, 0, 0);

                const thumbnail = canvas.toDataURL('image/jpeg', 0.8);
                setThumbnailUrl(thumbnail);
                URL.revokeObjectURL(video.src);
                resolve(null);
              };
            };
          });
        } catch {
          notify('error', defaultErrorMessage);
        }
      }
    };

    generateThumbnail();
  }, [fileInputValue]);

  const videoSrc = isFile(fileInputValue)
    ? URL.createObjectURL(fileInputValue)
    : getVideoUrl(import.meta.env.COHORT_ENV, fileInputValue, {
        h: Sizes.M,
        w: Sizes.M,
      });

  return (
    <div className="flex w-full justify-around">
      <div className="relative flex-col gap-y-2 first-line:flex" style={{width}}>
        {thumbnailUrl && isFile(fileInputValue) ? (
          <VideoPlayer aspectRatio="aspect-auto" videoSrc={videoSrc} thumbnailSrc={thumbnailUrl} />
        ) : (
          <VideoPlayer aspectRatio="aspect-auto" videoSrc={videoSrc} />
        )}
        {!hideCloseBtn && <FileInputDeleteButton />}
      </div>
    </div>
  );
};

type FileInputPreviewImageProps = {
  children?: React.ReactNode;
  width?: number | string;
  className?: string;
};

/** Component to display image preview */
const FileInputPreviewImage: React.FC<FileInputPreviewImageProps> = ({
  children,
  width = 140,
  className,
}) => {
  const {control, name} = useFileInputContext();
  const fieldValue = useWatch({
    control,
    name,
  });

  const imageSrc = isFile(fieldValue)
    ? URL.createObjectURL(fieldValue)
    : getImageUrl(import.meta.env.COHORT_ENV, fieldValue, {h: Sizes.S, w: Sizes.S});

  return (
    <div className="relative flex justify-between rounded-xl" style={{width}}>
      <img src={imageSrc} className={cn('h-full w-full rounded-xl', className)} />
      {children}
    </div>
  );
};

/** Component to display file metadata (name and size) */
const FileInputPreviewFileMetadata: React.FC = () => {
  const {control, name} = useFileInputContext();
  const fieldValue = useWatch({
    control,
    name,
  });

  if (!isFile(fieldValue)) {
    return null;
  }

  return (
    <div className="flex flex-col justify-center gap-y-[3px]">
      <p>{fieldValue.name}</p>
    </div>
  );
};

/** Component to display uploaded status message */
const FileInputPreviewUploadedFileMessage: React.FC = () => {
  const {t} = useTranslation('components', {keyPrefix: 'forms.fileInput'});
  const {control, name} = useFileInputContext();
  const currentFile = useWatch({
    control,
    name,
  });
  const fileType = getFileType(currentFile);

  if (isFile(currentFile)) {
    return null;
  }

  const computeText = (): string => {
    if (fileType === 'image') {
      return t('fileInputPreviewUploadedFileMessage.uploadedImage');
    }
    return t('fileInputPreviewUploadedFileMessage.uploadedVideo');
  };

  return (
    <div className="flex items-center gap-x-1.5">
      <CheckCircle size={20} className="text-green-600" />
      <div className="text-sm font-medium text-slate-700">{computeText()}</div>
    </div>
  );
};

/**
 * Displays the preview of the uploaded file.
 */
const FileInputPreview: React.FC<{children: React.ReactNode | React.ReactNode[]}> = ({
  children,
}) => {
  return (
    <div className="flex w-full justify-around rounded-lg border border-slate-200 bg-[--xps-input-background-color] p-4">
      <div className="flex flex-col items-center gap-y-2">{children}</div>
    </div>
  );
};

/**
 * Deletes the currently uploaded media file from the form field state.
 */
const FileInputDeleteButton: React.FC = () => {
  const {name, control} = useFileInputContext();
  const {field} = useController({control, name});

  return (
    <Button
      onClick={() => {
        field.onChange(null);
      }}
      variant="secondary"
      data-testid={`${name}-delete`}
      className="absolute right-2 top-2 gap-x-2 bg-black/40 p-1 text-white hover:bg-black/40 hover:text-white"
      tracking={{namespace: 'cohortForm.deleteFile'}}
    >
      <X size={16} />
    </Button>
  );
};

export {
  FileInput,
  FileInputUploader,
  FileInputUpload,
  FileInputPreviewImage,
  FileInputVideoPreview,
};
